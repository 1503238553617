<template>
  <div class="footer-mobile">
    <div class="main mobile">
      <div class="left">
        <div class="sub">
          <div class="sub-content">
            <div class="title">Subscribe for newsletter</div>
            <div class="input-value">
              <div class="input">
                <input
                  type="text"
                  placeholder="Enter your email address..."
                  v-model="email"
                  autocomplete="off"
                />
              </div>
              <div
                class="button search-button"
                v-if="notSubmit"
                @click="submitForm"
              >
                Subscribe
              </div>
              <div class="button search-button" v-else>Subscribing</div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="border"></div>-->
      <div class="company-solutions">

        <div class="legal mi">
          <div class="title">Solutions</div>
          <div class=" item search-button" @click="$router.push('/maxdata')">
            MaxData
          </div>
          <div class=" item search-button" @click="$router.push('/maxtrade')">
            MaxTrade
          </div>
          <div class=" item search-button" @click="$router.push('/max-sales')">
            MaxSales
          </div>
          <!--        <div class=" item search-button" @click="$router.push('/maxsell')">-->
          <!--          Max Sell-->
          <!--        </div>-->
        </div>
        <div class="connexima mi">
          <div class="title">Company</div>
          <!--        <div class="item search-button" @click="$router.push('/about-us')">-->
          <!--          About Us-->
          <!--        </div>-->
          <div class="item search-button" @click="$router.push('/contact-us')">
            Contact Us
          </div>
          <a href="https://calendly.com/jingqiu-uk4/30min" class="request-demo-link" target="_blank">
            <div class="item search-button">
              Request Demo
            </div>
          </a>
          <!--        <div-->
          <!--          class="item search-button"-->
          <!--          @click="$router.push('/user-action/register')"-->
          <!--        >-->
          <!--          Become a Seller-->
          <!--        </div>-->
        </div>
      </div>


      <!--      <div class="legal mi">-->
      <!--        <div class="title">Legal</div>-->

      <!--        <div class="privacy item">Privacy Policy</div>-->
      <!--        <div class="service item">Terms of Service</div>-->
      <!--      </div>-->

      <!--      <div class="sponsor">-->
      <!--        <div class="sponsor-title">-->
      <!--          Exclusive Sponsor-->
      <!--        </div>-->
      <!--        <div class="sponsor-img search-button">-->
      <!--          <a href="https://www.ingredientsonline.com" target="_blank"-->
      <!--            ><img src="@/assets/foot/sponsor.svg"-->
      <!--          /></a>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="right">
        <!--        <div class="follow">-->
        <!--          <div class="title">Follow Us</div>-->
        <!--          <div class="follow-content">-->
        <!--            <a-->
        <!--              href="https://www.facebook.com/Connexima-Online-Expo-112665470473302"-->
        <!--              target="_blank"-->
        <!--            >-->
        <!--              <div class="facebook item">-->
        <!--                <img src="@/assets/foot/facebook.png" />-->
        <!--              </div>-->
        <!--            </a>-->
        <!--            <a-->
        <!--              href="https://www.linkedin.com/company/connexima/"-->
        <!--              target="_blank"-->
        <!--            >-->
        <!--              <div class="link item">-->
        <!--                <img src="@/assets/foot/linkedin.png" />-->
        <!--              </div>-->
        <!--            </a>-->
        <!--            <div class="twitter item">-->
        <!--              <img src="@/assets/foot/twitter.png" />-->
        <!--            </div>-->
        <!--            <div class="wechat item">-->
        <!--              <img src="@/assets/foot/wechat.png" />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="reserve">© 2024 Connexima. All Rights Reserved.</div>
    </div>
    <MobileMenu/>
  </div>
</template>

<script>
  import MobileMenu from "@/components/mobileMenu";

  export default {
    components: {
      MobileMenu
    },
  data() {
    return {
      email: "",
      notSubmit: true
    };
  },
  methods: {
    submitForm() {
      this.notSubmit = false;
      if (this.email === "") {
        this.$Message.error("Please enter an email");
        this.notSubmit = true;
        return;
      }
      let emailPattern = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (emailPattern.test(this.email) == false) {
        this.$Message.error("Please enter a valid email");
        this.notSubmit = true;
        return;
      }
      this.$store.dispatch("subscribe/subscribe", this.email);
      this.email = "";
      this.notSubmit = true;
      this.$Message.success("success");
    }
  }
};
</script>

<style lang="less" scoped>
.footer-mobile {
  /*height:368px;*/
  width: 100%;
  background: #120D21;
  padding-bottom: 60px;
  .main {
    /*display: flex;*/
    margin: 0 auto;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    .left {
      display: flex;
      .sub {
        display: flex;
        .logo {
          width: 35px;
          height: 35px;
          margin: 20px 31px 30px 0;
          display: inline-block;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .sub-content {
          display: inline-block;
          position: relative;
          top: -16px;
          .title {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            line-height: 40px;
            margin-top: 20px;
          }
          .input-value {
            /*width:413px;*/
            display: flex;
            height: 42px;
            div {
              display: inline-block;
            }
            input {
              height: 35px;
              /*width:284px;*/
              padding-left: 5px;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border-right: none;
              outline: none;
              width: 170px;
              border: none;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
            .button {
              width: 115px;
              height: 35px;
              background: #FF4835;
              border-radius: 0px 5px 5px 0px;
              font-size: 16px;
              font-weight: 400;
              color: #feffff;
              /*line-height: 60px;*/
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .border {
      width: 1px;
      height: 368px;
      background: #2d2d2d;
      position: relative;
      /*top:81px;*/
      left: -65px;
    }
    .mi {
      margin-top: 10px;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;

        color: #f2f2f2;
        margin-bottom: 16px;
      }
      .item {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;

        color: #f2f2f2;
        margin-bottom: 10px;
      }
    }
    .connexima {
      margin-left: 50px;
    }
    .touch {
    }
    .legal {
    }
    .right {
      display: flex;
      margin: 30px 0px 0 0px;
      .follow {
        .title {
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
        }
        .follow-content {
          display: flex;
        }
        .item {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin: 11px 0;
          img {
            width: 26px;
            height: 26px;
            position: relative;
            top: 6px;
            margin-right: 20px;
          }
        }
      }
      .resource {
        margin-left: 55px;
        .title {
          font-size: 26px;
          font-weight: 400;
          color: #ffffff;
        }
        .item {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin: 11px 0;
        }
      }
    }
  }
  .reserve {
    display: flex;
    /*margin-bottom: 10px;*/
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    justify-content: center;

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 4 */

    color: #bdbdbd;
  }
}
.sponsor {
  margin-top: 20px;
  .sponsor-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;
    margin-bottom: 15px;
  }
  img {
    height: 45px;
  }
}
  .company-solutions{
    display: flex;
    justify-content: start;
  }
</style>
