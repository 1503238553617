import axios from "axios";
import { parse, compile } from "path-to-regexp";
import { API_PREFIX } from "@/config";
import { isObject } from "@/utils/helper";
import store from "@/store";
import {
  removeLocalInfo,
  setToken,
  setTokenExpiresSec,
  getToken
} from "@/utils/helper";
import qs from "qs";

axios.defaults.baseURL = API_PREFIX;

const api = {
  _searchPopular: ["get", "/search/popular"],

  _refreshToken: ["post", "/refreshToken"],
  _login: ["post", "/login"],
  _logout: ["post", "/logout"],
  _registerInfo: ["get", "/registerInfo"],
  _captcha: ["get", "/captcha"],

  _search: ["get", "/search"],
  _searchSuggestion: ["get", "/search/suggestion"],

  _emailState: ["get", "/email/:email/state"],

  _register: ["post", "/register"],
  _verify: ["get", "/email/verify/:token"],
  _verifyInvite: ["post", "/user/acceptJoin/:token"],

  _forgetPassword: ["post", "/forgetPassword"],
  _resetForgottenPassword: ["post", "/resetForgottenPassword/:token"],
  _resetPassword: ["put", "/resetPassword"],
  _sendVerifyEmail: ["post", "/sendVerifyEmail"],
  _country: ["get", "/country"],
  _rating: ["put", "/company/:id/rating"],

  _countDownload: ["post", "/document/:id/download"],

  _queryMe: ["get", "/me"],
  _editMe: ["put", "/me"],

  _queryMyCompany: ["get", "/myCompany"],
  _editMyCompany: ["put", "/myCompany"],
  _editMyCompanyBanner: ["put", "/myCompany/landingImage"],
  _editMyCompanyAvatar: ["put", "/myCompany/avatar"],
  _editMyCompanyDescAndOthers: ["put", "/myCompany/descAndOthers"],

  _queryNotification: ["get", "/notification"],
  _notifiReadAll: ["put", "/notification/readAll"],
  _notifiRead: ["put", "/notification/type/:type/paramId/:id/read"],

  _queryAds: ["get", "/ads"],
  _hisAds: ["post", "/a-d-s/history"],

  _queryAnalysisCompanies: ["get", "/myCompany/storeFrontViewedHistory"],
  _queryAnalysisProducts: ["get", "/myCompany/productViewedHistory"],

  // # calendar
  _queryAvailableUser: ["get", "/meeting/user"],
  _createMeeting: ["post", "/meeting/company/:id/meeting"],
  _reviewMeeting: ["put", "/meeting/:id/review"],
  _queryAllMeeting: ["get", "/meeting/companyMeeting"],

  _queryMyAgenda: ["get", "/meeting/agenda"],
  _queryAnyExhibitorCompanyAgenda: ["get", "/meeting/company/:id/agenda"],
  _queryAdminCompanyAgenda: ["get", "/meeting/myCompanyAgenda"],

  // # company
  _queryCompanyCompany: ["get", "/company/:id"],
  _queryExhibitor: ["get", "/exhibitor"],
  _queryExhibitorPartProduct: ["get", "/exhibitor/partProduct"],
  _queryCompanyUser: ["get", "/company/:id/user"],
  _queryCompanyProduct: ["get", "/company/:id/product"],
  _queryCompanyBasicProduct: ["get", "/company/:id/basicProduct"],
  _queryCompanyFactory: ["get", "/company/:id/factory"],
  _queryCompanyDocument: ["get", "/company/:id/document"],
  _queryCompanyMarketing: ["get", "/company/:id/marketing"],
  _exchangeBusinessCard: ["put", "/company/:id/exchangeBusinessCard"],
  _queryStatisticsByTag: ["get", "/company/:id/productStatisticsByTag"],

  _queryDocumentAssociated: ["get", "/document/:document_id/:type"],

  // # CRUD members
  _queryMemberList: ["get", "/user"],
  _editMember: ["put", "/user/:id"],
  _queryMember: ["get", "/user/:id"],
  _inviteMember: ["post", "/user/invite"],
  _removeMember: ["delete", "/user/:id"],
  _registerMember: ["post", "/user/accept/:token"],
  _queryAcceptMember: ["get", "/user/accept/:token"],
  _queryTimezone: ["get", "/user/timezone"],
  _querySaleProduct: ["get", "/user/:id/saleProduct"],
  _delSaleProduct: ["delete", "/user/:id/saleProduct/:proid"],
  _queryUnassignProduct: ["get", "/user/:id/unassignedSaleProduct"],
  _saleProduct: ["post", "/user/:id/saleProduct"],
  _changeActive: ["put", "/user/:id/active"],

  // # CRUD conference
  _queryMyConferenceList: ["get", "/conference/myCompanyAllConference"],
  _queryConferenceList: ["get", "/conference"],
  _querymyUpcomingConferenceList: ["get", "/conference/myUpcomingConference"],
  _querymyConferenceList: [
    "get",
    "/conference/myCompanyLiveAndUpComingConference"
  ],
  _queryConference: ["get", "/conference/:id"],
  _applyConference: ["put", "/conference/:id/apply"],

  // # forum
  _queryForum: ["get", "/forum/login"],

  // # CRUD businessCardCompany
  _queryBusinessCardCompanyList: ["get", "/businessCardCompany"],

  // # CRUD product
  _queryProductRelatedInfo: ["get", "/product/relatedInfo"],
  _queryAllProductList: ["get", "/allProduct"],
  _queryMyProductList: ["get", "/product"],
  _queryMyProductListSort: ["get", "/product/published"],
  _setSorts: ["post", "/product/rank"],
  _createProduct: ["post", "/product"],
  _queryProduct: ["get", "/product/:id"],
  _queryProductPrice: ["get", "/product/:id/price"],
  _modeProductPrice: ["put", "/product/price/:id"],
  _delProductPrice: ["delete", "/product/price/:id"],
  _editProduct: ["put", "/product/:id"],
  _removeProduct: ["delete", "/product/:id"],
  _createDraft: ["post", "/product/draft"],
  _editDraft: ["put", "/product/:id/draft"],
  _publishDraft: ["put", "/product/:id/draft/publish"],
  _publishProduct: ["put", "/product/:id/publish"],
  _unpublishProduct: ["put", "/product/:id/unpublish"],

  // # CRUD product -- sample
  _querySampleRelatedInfo: ["get", "/product/sampleRequestRelatedInfo"],
  _querySampleList: ["get", "/product/sampleRequest"],
  _createSample: ["post", "/product/sampleRequest"],
  _querySample: ["get", "/product/sampleRequest/:id"],
  _checkSample: ["put", "/product/sampleRequest/:id/review"],
  _numberSample: ["put", "/product/sampleRequest/:id/trackingNumber"],

  // # CRUD factory
  _queryFactoryList: ["get", "/factory"],
  _createFactory: ["post", "/factory"],
  _queryFactory: ["get", "/factory/:id"],
  _queryFeaturedFactory: ["get", "/factory/:id/featuredProduct"],
  _editFactory: ["put", "/factory/:id"],
  _removeFactory: ["delete", "/factory/:id"],
  // # CRUD show
  _queryShowList: ["get", "/show"],
  _createShow: ["post", "/show"],
  _queryShow: ["get", "/show/:id"],
  _editShow: ["put", "/show/:id"],
  _removeShow: ["delete", "/show/:id"],
// # CRUD webinar
  _queryWebinarList: ["get", "/webinar"],
  _createWebinar: ["post", "/webinar"],
  _queryWebinar: ["get", "/webinar/:id"],
  _editWebinar: ["put", "/webinar/:id"],
  _removeWebinar: ["delete", "/webinar/:id"],
  // # CRUD document
  _queryDocumentRelatedInfo: ["get", "/documentRelatedInfo"],
  _queryDocumentList: ["get", "/document"],
  _createDocument: ["post", "/document"],
  _queryDocument: ["get", "/document/:id"],
  _editDocument: ["put", "/document/:id"],
  _removeDocument: ["delete", "/document/:id"],
  _downloadHistory: ["get", "/downloadHistory"],

  // # CRUD marketing
  _queryMarketingList: ["get", "/marketing"],
  _createMarketing: ["post", "/marketing"],
  _queryMarketing: ["get", "/marketing/:id"],
  _editMarketing: ["put", "/marketing/:id"],
  _removeMarketing: ["delete", "/marketing/:id"],

  // # CRUD category
  _queryCategoryList: ["get", "/category"],
  _createCategory: ["post", "/category"],
  _queryCategory: ["get", "/category/:id"],
  _editCategory: ["put", "/category/:id"],
  _removeCategory: ["delete", "/category/:id"],

  // ask question
  _createQuestion: ["post", "/question"],

  // sourcing
  _createSourcing: ["post", "/sourceInquiry"],
  _editSourcing: ["put", "/sourceInquiry/:id"],
  _querySourcing: ["get", "/sourceInquiry"],
  _queryMySourcing: ["get", "/mySourceInquiry"],
  _queryMySourcingQuotes: ["get", "/mySourceInquiry/:id/quotes"],
  _queryMySourcingQuote: ["get", "/mySourceInquiry/:id/quote/:quote"],
  _closeMySourcing: ["put", "/mySourceInquiry/:id/close"],
  _deleteMySourcing: ["delete", "/mySourceInquiry/:id"],
  _querySingleSourcing: ["get", "/sourceInquiry/:id"],
  _querySourcingType: ["get", "/sourceInquiry/type"],
  _querySourcingTypeCate: ["get", "/sourceInquiry/typeCate"],
  _queryCollectionSourcingTypeCate: [
    "get",
    "/mySourceInquiry/collectionTypeCate"
  ],
  _queryMySourcingTypeCate: ["get", "/mySourceInquiry/myTypeCate"],
  _queryMySourcingType: ["get", "/mySourceInquiry/type"],
  _chooseInquiryQuote: ["put", "/inquiryQuote/:id"],
  _removeSourcing: ["delete", "/sourceInquiry/:id"],
  _ExchangeSourcing: ["post", "/sourceInquiry/:id/exchangeBusinessCard"],
  _leaveMessage: ["post", "/sourceInquiry/:id/message"],
  _quoteInquiry: ["post", "/sourceInquiry/:id/quote"],
  _reviewInquiry: ["post", "/sourceInquiry/:id/review"],
  // # chat
  _queryChannelList: ["get", "/agora/channel"],

  // # twilio
  _queryTwilioToken: ["post", "/twilio/token"],
  _createChat: ["post", "/twilio/channel/create"],
  _sendMessage: ["post", "/twilio/channel/message"],

  //add
  _queryRecommendExhibitor: ["get", "/exhibitor/recommend"],
  _dataSearch: ["get", "/data-search"],
  _dataSearchTypeCount: ["get", "/data-search-type-counts"],
  _dataSearchExport: ["post", "/data-search-export-all-data"],
  _dataSearchInfo: ["get", "/data-search-info"],
  _queryHotProduct: ["get", "/product/hot"],
  _updateIndustry: ["post", "/industryAndArea"],

  _createBasicProduct: ["post", "/basicProduct"],
  _queryMyBasicProductList: ["get", "/basicProduct"],

  _createContact: ["post", "/contact-us"],
  _createSubscribe: ["post", "/subscribe"],
  _createFavorite: ["post", "/favorite"],
  _deleteFavorite: ["delete", "/favorite/:id"],
  _queryFavorite: ["get", "/favorite"],
  _querySearchRecords: ["get", "/data-search-records"],
  _batchUploadProductToSell: ["post", "/sourceInquiry/batchUpload"],
  _querySearchPromotion: ["get", "/data-search-promotion"],
  _querySearchPromotionSourcing: ["get", "/data-search-promotion-sourcing"],
  _querySearchPromotionQuotation: ["get", "/data-search-promotion-quotation"],
  _createInquiryImages: ["post", "/sourceInquiryImage/image"],
  _deleteInquiryImages: ["delete", "/sourceInquiryImage/image"],
  _queryMyInquiryImages: ["get", "/sourceInquiryImage/image"],
  _queryInquiryImages: ["get", "/sourceInquiryImage/:id/companyImage"],
  _reSendActiveEmail: ["get", "/reSendVerifyEmail/:token"],
  _queryTradeCollection: ["get", "/sourcingInquiryCollection"],
  _storeTradeCollection: ["post", "/sourcingInquiryCollection"],
  _deleteTradeCollection: ["delete", "/sourcingInquiryCollection/:id"],
  _socialAuth: ["get", "/auth/:param"],
  _socialAuthCallBack: ["get", "/auth/callback/:param"],
  _authRegister: ["post", "/authRegister"],
  _getInfoViaUniqStr: ["post", "/infoViaUnqiStr"],
  _postSocialAuth: ["post", "/auth"],
  _postAuthEmail: ["post", "/authEmail"],
  _postAuthEmailCode: ["post", "/authEmailCode"],

  _package: ["post", "/package"],
  _getStripeSessionId: ["get", "/stripe/session"],
  _getPaymentSuccess: ["get", "/stripe/success"],
  _getCompanyContacts: ["get", "/data-search-contact"],
  _getCompanyPreview: ["get", "/data-search-company-preview"],
  _getMyPackage: ["get", "/myPackage"],
  _getTradeShow: ["get", "/trade-shows"],
  _getIsAttendTradeShow: ["get", "/trade-show/attend"],
  _AttendTradeShow: ["post", "/trade-show/attend-show"],
  _AttendTheTradeShow: ["post", "/trade-show/attend-the-show"],
  _getContracts: ["get", "/contract-mnfr"],
  _getTradeCompanies: ["get", "/trade-show/companies"],
  _getSingleTradeCompanies: ["get", "/trade-show/:id/companies"],
  _getSingleCompanyShows: ["get", "/company/:id/shows"],
  _getAllTradeShowCompanies: ["get", "/trade-shows-companies"],
  _getAllTradeShowBannerCompanies: ["get", "/trade-show-banner-companies"],
  _contactTradeShowCompany: ["post", "/trade-show/contact/:id/show/:show_id"],
  _directContactTradeShowCompany: ["post", "/trade-show/company/:id"],
  _getTradeShowConfig: ["get", "/trade-show/config"],
  _getMyStoreFront: ["get", "/trade-show/myCompany"],
  _getContactDetail: ["get", "/data-search-contact/:id"],
  _getUserInvitation: ["get", "/user/invitation-url"],
};

let refreshing = false;
let requestArr = [];

const createQueuePromise = obj => {
  return new Promise(res => {
    requestArr.push(() => {
      // console.log("call=====");
      res(request(obj));
    });
  });
};

const request = obj => {
  const { url, method, data, apiKey } = obj;
  // console.log(apiKey);

  if (store.getters.token && !getToken()) {
    window.location.reload();
  }

  return new Promise((res, rej) => {
    const accessToken = store.getters.token;
    const expiresSec = store.getters.expiresSec;

    if (refreshing && apiKey !== "_refreshToken") {
      // push in waiting-queue
      return res(createQueuePromise(obj));
    }

    const curSec = parseInt(new Date().getTime() / 1000, 10);
    // console.log({ expiresSec, curSec });

    if (expiresSec) {
      if (Number(expiresSec) < curSec && refreshing === false) {
        // outdate
        refreshing = true;

        api
          ._refreshToken()
          .then(response => {
            refreshing = false;
            console.log("_refresh", response);
            const { data } = response;

            const curSec = parseInt(new Date().getTime() / 1000, 10);
            const expiresSec = curSec + data.expires_in - 10; // 10 sec before

            store.commit("user/common", { expiresSec });
            store.commit("user/common", { token: data.token });

            setTokenExpiresSec(expiresSec);
            setToken(data.token);

            requestArr.forEach(item => {
              item();
            });
            requestArr = [];
          })
          .catch(err => {
            console.log("refresh err", err);
            refreshing = false;
            removeLocalInfo();
            window.location.reload();
          });

        return res(createQueuePromise(obj));
      }
    }

    // ================

    let headers = {
      Authorization: `Bearer ${accessToken}`
    };

    const difference = {};

    if (method === "get") {
      difference.params = data; // special for get method
    } else {
      difference.data = qs.stringify(data); // other method
      headers = {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded"
      };
    }

    axios({
      url,
      method,
      ...difference,
      headers
    })
      .then(response => {
        // console.log("2xx");
        // 2xx
        const { data } = response;
        if (data.code < 0) {
          rej({
            code: data.code,
            message: data.msg
          });
        } else {
          res(data);
        }
      })
      .catch(err => {
        // logout always reslove
        if (apiKey === "_logout") {
          return res();
        }

        if (err.response) {
          // console.log("4xx 5xx");
          // 4xx 5xx
          const { status, message, data } = err.response;
          let msg = data.message || message || err.message;

          if (status === 422) {
            const firstErrorKey = Object.keys(data.errors)[0];
            msg = data.errors[firstErrorKey][0];
          }

          if (status === 500) {
            msg = "server error";
          }

          rej({
            message: msg
          });
        } else {
          // timeout
          // bad proxy/network
          rej({
            message: err.message
          });
        }
      });
  });
};

Object.keys(api).forEach(apiKey => {
  const [method, url] = api[apiKey];
  const match = parse(url);

  api[apiKey] = data => {
    let compileUrl;

    try {
      compileUrl = compile(url)(data);
    } catch (err) {
      console.error(err);
    }

    match.forEach(item => {
      if (isObject(item) && item.name in data) {
        delete data[item.name];
      }
    });

    return request({
      url: compileUrl,
      method,
      data,
      apiKey
    });
  };
});

export default api;
