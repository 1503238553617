<template>
  <div v-if="mobileWidth">
    <mobile />
  </div>
  <div v-else-if="padWidth">
    <pad />
  </div>
  <div class="footer" v-else>
    <div class="main">
      <div class="left">
        <div class="sub">
          <div class="sub-content">
            <div class="title">Subscribe for newsletter</div>
            <div class="content">
              Sign up to receive email updates on new product and feature
              announcements, special promotions, industry insights and more.
            </div>
            <div class="input-value">
              <div class="input">
                <input
                  type="text"
                  placeholder="Enter your email address..."
                  v-model="email"
                  autocomplete="off"
                />
              </div>
              <div
                class="button search-button"
                v-if="notSubmit"
                @click="submitForm"
              >
                Subscribe
              </div>
              <div class="button search-button" v-else>Subscribing</div>
            </div>
          </div>

          <!--          <div class="privacy-and-service">-->
          <!--            <div class="privacy search-button">Privacy Statement</div>-->
          <!--            <div class="service search-button">Terms of Service</div>-->
          <!--          </div>-->
        </div>
      </div>
      <!--      <div class="border"></div>-->
      <div class="connexima mi">
        <div class="title">Company</div>
        <!--        <div class="item search-button" @click="$router.push('/about-us')">-->
        <!--          About Us-->
        <!--        </div>-->
        <!-- <div class="item search-button" @click="$router.push('/max-sales?contact=true')">
          Contact Us
        </div> -->
        <div class="item search-button" @click="$router.push('/contact-us')">
          Contact Us
        </div>
        <a href="https://calendly.com/jingqiu-uk4/30min" class="request-demo-link" target="_blank">
        <div class="item search-button">
          Request Demo
        </div>
        </a>
        <!--        <div-->
        <!--          class="item search-button"-->
        <!--          @click="$router.push('/user-action/register')"-->
        <!--        >-->
        <!--          Become a Seller-->
        <!--        </div>-->
      </div>
      <div class="legal mi">
        <div class="title">Services</div>
        <div class=" item search-button" @click="$router.push('/maxdata')">
          MaxData
        </div>
        <div class=" item search-button" @click="$router.push('/maxtrade')">
          MaxTrade
        </div>
        <div class=" item search-button" @click="$router.push('/max-sales')">
          MaxSales
        </div>
        <!--        <div class=" item search-button" @click="$router.push('/maxsell')">-->
        <!--          Max Sell-->
        <!--        </div>-->
      </div>
      <!--      <div class="sponsor">-->
      <!--        <div class="sponsor-title">-->
      <!--          Exclusive Sponsor-->
      <!--        </div>-->
      <!--        <div class="sponsor-img search-button">-->
      <!--          <a href="https://www.ingredientsonline.com" target="_blank"-->
      <!--            ><img src="@/assets/foot/sponsor.svg"-->
      <!--          /></a>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="right">
        <div class="logo">
          <svg
            width="160"
            height="109"
            viewBox="0 0 185 126"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1115_4008)">
              <path
                d="M11.47 103.061C14.6381 103.061 16.9969 104.06 19.1475 106.033C19.425 106.288 19.425 106.66 19.1706 106.915L16.6962 109.492C16.465 109.725 16.1181 109.725 15.91 109.492C14.7306 108.448 13.1812 107.867 11.6319 107.867C8.0475 107.867 5.41125 110.862 5.41125 114.438C5.41125 117.967 8.07062 120.915 11.655 120.915C13.1581 120.915 14.7769 120.381 15.91 119.383C16.1412 119.197 16.5344 119.197 16.7425 119.406L19.2169 122.053C19.4481 122.285 19.4019 122.68 19.1937 122.912C17.0431 125.025 14.3144 126 11.47 126C5.11062 126 0 120.939 0 114.554C0 108.169 5.11062 103.061 11.47 103.061Z"
                fill="white"
              />
              <path
                d="M31.4036 103.061C37.7629 103.061 42.8273 108.192 42.8273 114.577C42.8273 120.962 37.7398 126.023 31.4036 126.023C25.0673 126.023 20.0029 120.962 20.0029 114.577C20.0029 108.192 25.0442 103.061 31.4036 103.061ZM31.4036 120.915C34.8954 120.915 37.7629 118.036 37.7629 114.577C37.7629 111.071 34.8954 108.169 31.4036 108.169C27.9348 108.169 25.0904 111.071 25.0904 114.577C25.0904 118.036 27.9348 120.915 31.4036 120.915Z"
                fill="white"
              />
              <path
                d="M45.3711 103.619C45.3711 103.293 45.6486 103.038 45.9723 103.038H46.7586L59.4311 115.274H59.4542V103.967C59.4542 103.642 59.7086 103.363 60.0555 103.363H63.8711C64.1949 103.363 64.4723 103.642 64.4723 103.967V125.42C64.4723 125.745 64.1949 126 63.8711 126H63.3623C63.2698 126 63.0155 125.907 62.9461 125.837L50.4123 113.184H50.3892V125.071C50.3892 125.396 50.1348 125.675 49.788 125.675H46.0186C45.6948 125.675 45.4173 125.396 45.4173 125.071L45.3711 103.619Z"
                fill="white"
              />
              <path
                d="M68.6348 103.619C68.6348 103.293 68.9123 103.038 69.236 103.038H70.0223L82.6948 115.274H82.7179V103.967C82.7179 103.642 82.9723 103.363 83.3191 103.363H87.1348C87.4585 103.363 87.736 103.642 87.736 103.967V125.42C87.736 125.745 87.4585 126 87.1348 126H86.626C86.5335 126 86.2791 125.907 86.2098 125.837L73.676 113.184H73.6529V125.071C73.6529 125.396 73.3985 125.675 73.0516 125.675H69.2823C68.9585 125.675 68.681 125.396 68.681 125.071L68.6348 103.619Z"
                fill="white"
              />
              <path
                d="M91.9448 103.967C91.9448 103.642 92.1992 103.363 92.5461 103.363H105.681C106.028 103.363 106.282 103.642 106.282 103.967V107.45C106.282 107.775 106.028 108.053 105.681 108.053H96.8936V112H104.132C104.455 112 104.733 112.279 104.733 112.604V116.086C104.733 116.435 104.455 116.69 104.132 116.69H96.8936V120.985H105.681C106.028 120.985 106.282 121.264 106.282 121.589V125.071C106.282 125.396 106.028 125.675 105.681 125.675H92.5461C92.1992 125.675 91.9448 125.396 91.9448 125.071V103.967V103.967Z"
                fill="white"
              />
              <path
                d="M114.607 114.252L108.202 104.269C107.947 103.851 108.202 103.386 108.734 103.386H113.359C113.59 103.386 113.775 103.549 113.867 103.665L117.521 109.585H117.544L121.267 103.665C121.337 103.549 121.545 103.386 121.776 103.386H126.401C126.933 103.386 127.234 103.874 126.933 104.269L120.481 114.252L127.141 124.793C127.419 125.234 127.141 125.675 126.632 125.675H122.215C122.03 125.675 121.776 125.559 121.684 125.396L117.521 118.965H117.452L113.382 125.396C113.289 125.513 113.104 125.675 112.85 125.675H108.433C107.901 125.675 107.6 125.187 107.901 124.793L114.607 114.252Z"
                fill="url(#paint0_linear_1115_4008)"
              />
              <path
                d="M129.431 103.967C129.431 103.642 129.708 103.363 130.032 103.363H133.848C134.171 103.363 134.449 103.642 134.449 103.967V125.071C134.449 125.396 134.171 125.675 133.848 125.675H130.032C129.708 125.675 129.431 125.396 129.431 125.071V103.967Z"
                fill="white"
              />
              <path
                d="M140.831 103.572C140.854 103.293 141.155 103.061 141.409 103.061H141.918C142.08 103.061 142.357 103.177 142.45 103.386L149.827 117.154H149.896L157.273 103.386C157.365 103.201 157.643 103.061 157.805 103.061H158.314C158.568 103.061 158.845 103.293 158.892 103.572L162.476 125.002C162.545 125.419 162.245 125.698 161.875 125.698H158.129C157.851 125.698 157.55 125.443 157.527 125.187L156.094 115.343H156.024L150.682 125.675C150.59 125.861 150.312 126.023 150.15 126.023H149.572C149.387 126.023 149.133 125.861 149.04 125.675L143.675 115.343H143.606L142.219 125.187C142.195 125.443 141.941 125.698 141.617 125.698H137.871C137.501 125.698 137.2 125.419 137.27 125.002L140.831 103.572Z"
                fill="white"
              />
              <path
                d="M163.656 124.862L173.599 103.41C173.692 103.224 173.923 103.061 174.131 103.061H174.455C174.686 103.061 174.894 103.224 174.987 103.41L184.931 124.862C185.116 125.28 184.861 125.698 184.399 125.698H180.884C180.306 125.698 180.051 125.512 179.774 124.909L178.641 122.401H170.015L168.882 124.955C168.72 125.327 168.373 125.721 167.749 125.721H164.257C163.725 125.675 163.471 125.28 163.656 124.862ZM176.721 118.036L174.316 112.766H174.293L171.911 118.036H176.721Z"
                fill="white"
              />
              <path
                d="M130.032 24.5174L112.688 41.3266C111.231 42.7429 111.231 45.9469 112.688 47.3631L130.032 64.1724C131.304 65.4029 132.969 63.6384 132.969 61.1309V27.5588C132.969 25.0513 131.304 23.2868 130.032 24.5174Z"
                fill="white"
              />
              <path
                d="M60.0325 76.1525L93.6563 47.99C95.7606 46.2255 95.7606 42.4411 93.6563 40.6998L60.0325 12.5372C56.6794 9.72796 52.0312 11.5621 52.0312 15.8573V72.8556C52.0312 77.1276 56.6794 78.9618 60.0325 76.1525Z"
                fill="white"
              />
              <path
                d="M104.386 36.126L123.464 20.1526C125.314 18.6202 124.435 13.9768 122.192 13.466L77.6073 3.04146C73.6992 2.13599 72.1267 7.77778 75.4336 10.9818L101.125 35.8707C102.12 36.8458 103.415 36.9386 104.386 36.126Z"
                fill="url(#paint1_linear_1115_4008)"
              />
              <path
                d="M101.565 51.8906L75.873 76.8027C72.5662 80.0066 74.1387 85.6484 78.0468 84.743L122.632 74.3184C124.875 73.7844 125.731 69.1642 123.904 67.6319L104.826 51.6584C103.854 50.8458 102.559 50.9387 101.565 51.8906Z"
                fill="url(#paint2_linear_1115_4008)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_1115_4008"
                x1="107.833"
                y1="114.529"
                x2="127.299"
                y2="114.529"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F99D20" />
                <stop offset="1" stop-color="#F57F34" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1115_4008"
                x1="73.637"
                y1="19.8175"
                x2="124.453"
                y2="19.8175"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F99D20" />
                <stop offset="1" stop-color="#F57F34" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1115_4008"
                x1="74.0817"
                y1="67.9708"
                x2="124.898"
                y2="67.9708"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F99D20" />
                <stop offset="1" stop-color="#F57F34" />
              </linearGradient>
              <clipPath id="clip0_1115_4008">
                <rect width="185" height="126" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <!--        <div class="follow">-->
        <!--          <div class="title">Follow Us</div>-->
        <!--          <div class="follow-content">-->
        <!--            <a-->
        <!--              href="https://www.facebook.com/Connexima-Online-Expo-112665470473302"-->
        <!--              target="_blank"-->
        <!--            >-->
        <!--              <div class="facebook item">-->
        <!--                <img src="@/assets/foot/facebook.png" />-->
        <!--              </div>-->
        <!--            </a>-->
        <!--            <a-->
        <!--              href="https://www.linkedin.com/company/connexima/"-->
        <!--              target="_blank"-->
        <!--            >-->
        <!--              <div class="link item">-->
        <!--                <img src="@/assets/foot/linkedin.png" />-->
        <!--              </div>-->
        <!--            </a>-->
        <!--            <div class="twitter item">-->
        <!--              <img src="@/assets/foot/twitter.png" />-->
        <!--            </div>-->
        <!--            <div class="wechat item">-->
        <!--              <img src="@/assets/foot/wechat.png" />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="reserve">© 2024 Connexima. All Rights Reserved.</div>
  </div>
</template>

<script>
import mobile from "./CFooter-mobile";
import pad from "./CFooter-pad";

export default {
  components: { mobile,pad },
  data() {
    return {
      mobileWidth: window.innerWidth < this.$mobileWidth ,
      padWidth: window.innerWidth >= this.$mobileWidth && window.innerWidth <= this.$padWidth,
      currentWidth: window.innerWidth,
      email: "",
      notSubmit: true
    };
  },
  mounted() {
    window.addEventListener('resize',() => this.changeWidth(), false)
  },
  watch: {
    currentWidth(val) {
      this.currentWidth = val
      this.mobileWidth = this.currentWidth <= this.$mobileWidth
      this.padWidth = this.currentWidth > this.$mobileWidth && this.currentWidth <= this.$padWidth;
    }
  },
  methods: {
    changeWidth(){
      this.currentWidth = document.documentElement.clientWidth
    },
    submitForm() {
      this.notSubmit = false;
      if (this.email === "") {
        this.$Message.error("Please enter an email");
        this.notSubmit = true;
        return;
      }
      let emailPattern = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (emailPattern.test(this.email) == false) {
        this.$Message.error("Please enter a valid email");
        this.notSubmit = true;
        return;
      }
      this.$store.dispatch("subscribe/subscribe", this.email);
      this.email = "";
      this.notSubmit = true;
      this.$Message.success("success");
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.changeWidth(), false)
  }
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .input-value {
    width: 413px;

    input {
      width: 284px;
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .input-value {
    width: 353px;

    input {
      width: 224px;
    }
  }
  .logo {
    /*width: 275px !important;*/
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .input-value {
    width: 370px;

    input {
      width: 240px;
    }
  }
  .left {
    .sub {
      .logo {
        /*width: 260px !important;*/
      }
    }
  }
  .footer .main .right {
    /*margin:138px 0px 0 0px !important;*/
    .resource {
      margin-left: 70px !important;
    }
  }
}
@media (max-width: 1200px) {
  .input-value {
    width: 253px;

    input {
      width: 104px;
    }
  }
}
.footer {
  height: 315px;
  width: 100%;
  background: #120D21 !important;
  z-index: 999;
  position: sticky;
  .main {
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    /*padding-left: 30px;*/
    /*padding-right: 30px;*/
    .left {
      margin-top: 70px;
      .sub {
        .logo {
          /*width:282px;*/
          height: 49px;
          margin: 73px 31px 120px 0;
          display: inline-block;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .sub-content {
          display: inline-block;
          position: relative;
          top: -16px;
          .title {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 60px;
          }
          .content {
            font-weight: normal;
            font-size: 14px;
            /*line-height: 17px;*/
            display: flex;
            align-items: center;

            color: #ffffff;
            width: 447px;
            margin-bottom: 10px;
          }
          .input-value {
            /*width:413px;*/
            height: 45px;
            display: flex;
            div {
              display: inline-block;
            }
            input {
              height: 45px;
              /*width:284px;*/
              padding-left: 10px;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border-right: none;
              outline: none;
              border: none;
            }
            .button {
              width: 110px;
              height: 45px;
              background: #FF4835;
              border-radius: 0px 5px 5px 0px;
              font-size: 17px;
              font-weight: 400;
              color: #feffff;
              /*line-height: 60px;*/
              text-align: center;
              /*position: relative;*/
              /*padding-top: 6px;*/
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .privacy-and-service {
          display: flex;
          margin-top: 40px;
          .privacy {
            margin-right: 33px;
          }
          .search-button {
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            /* or 214% */

            display: flex;
            align-items: center;
            text-align: center;
            text-decoration-line: underline;

            color: #ffffff;
          }
        }
        .reserve {
          position: relative;
          bottom: 62px;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Gray 4 */

          color: #eee;
        }
      }
    }
    .border {
      width: 1px;
      height: 368px;
      background: #2d2d2d;
      position: relative;
      /*top:81px;*/
      left: -65px;
    }
    .mi {
      margin-top: 70px;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;

        color: #f2f2f2;
        margin-bottom: 16px;
      }
      .item {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        color: #f2f2f2;
        margin-bottom: 10px;
      }
    }
    .connexima {
    }
    .touch {
    }
    .legal {
    }
    .right {
      /*display: flex;*/
      margin: 70px 0px 0 0px;
      .logo{
        svg{
          width: 160px;
          /*height: 100px;*/
        }
      }
      .reserve {
        margin-top: 60px;
      }
      .follow {
        .follow-content {
          display: flex;
        }

        .title {
          font-weight: bold;
          font-size: 18px;
          line-height: 30px;
          /* identical to box height, or 167% */

          display: flex;
          align-items: center;
          text-align: center;

          /* White */

          color: #ffffff;

          margin-top: 90px;
        }
        .item {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin: 11px 0;
          img {
            width: 26px;
            height: 26px;
            position: relative;
            top: 6px;
            margin-right: 20px;
          }
        }
      }
      .resource {
        margin-left: 55px;
        .title {
          font-size: 26px;
          font-weight: 400;
          color: #ffffff;
        }
        .item {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin: 11px 0;
        }
      }
    }
  }
}
.sponsor {
  margin-top: 70px;
  .sponsor-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;
    margin-bottom: 13px;
  }
  img {
    height: 40px;
  }
}
.reserve {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  color: #bdbdbd;
}
</style>
