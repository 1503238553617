import router from "./router";
import store from "./store";
import { pathToRegexp } from "path-to-regexp";

// no redirect whitelist
const whiteList = [
  "/company/:id",
  "/products/:id",
  "/exhibitors",
  "/products",
  "/sourcingso",
  "/user-action/login",
  "/user-action/forgot-password",
  "/user-action/reset-password",
  "/user-action/register",
  "/user-action/register/attendee",
  "/user-action/register/exhibitor",
  "/user-action/register-success",
  "/user-action/invite",
  "/user-action/verify",
  "/user-action/verify-invite",
  "/user-action/welcome-exhibitor",
  "/user-action/welcome-attendee",
  "/user-action/welcome-invite",
  "/user-action/privacy",
  "/user-action/terms",
  "/index",
  "/exhibitors",
  "/storefront",
  "/products",
  "/blank",
  "/data-search",
  "/maxdata",
  "/company/:id/products",
  "/sourcing-inquiries",
  "/maxsource",
  "/sourcing-quotations",
  "/maxsell",
  "/sourcing/:id",
  "/sourcing-quotation/:id",
  "/sell-on-connexima",
  "/contact-us",
  "/about-us",
  "/maxtrade",
  "/buy",
  "/sell",
  "/trades",
  "/trade-collection",
  "/",
  "/me",
  "/max-sales",
  "/slug/:slug",
  "/trade-show",
  "/buyer-space",
  "/contract-mnfr",
  "/show/supply-side-west",
];

router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in
  const hasToken = !!store.getters.token;

  if (hasToken) {
    if (to.path === "/user-action/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
    } else {
      // get user info
      if (!store.state.user.email) {
        try {
          // only call once 1
          store.dispatch("chat/login");
          console.info("login chat");
          await store.dispatch("user/queryMe");
          next();
        } catch (err) {
          await store.dispatch("user/exit");
          next(`/user-action/login?redirect=${to.fullPath}`);
        }
      } else {
        next();
      }

      // // determine whether the user has obtained his permission roles through getInfo
      // const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      // if (hasRoles) {
      //   next();
      // } else {
      //   try {
      //     // get user info
      //     // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
      //     const { roles } = await store.dispatch("user/getInfo");
      //     // generate accessible routes map based on roles
      //     const accessRoutes = await store.dispatch(
      //       "permission/generateRoutes",
      //       roles
      //     );
      //     // dynamically add accessible routes
      //     router.addRoutes(accessRoutes);
      //     // hack method to ensure that addRoutes is complete
      //     // set the replace: true, so the navigation will not leave a history record
      //     next({ ...to, replace: true });
      //   } catch (error) {
      //     // remove token and go to login page to re-login
      //     await store.dispatch("user/resetToken");
      //     // Message.error(error || "Has Error");
      //     next(`/user-action/login?redirect=${to.path}`);
      //   }
      // }
    }
  } else {
    /* has no token*/
    if (to.path === "/home") {
      return next("/index");
    }

    let flag = false;
    whiteList.forEach(item => {
      if (pathToRegexp(item).exec(to.path)) {
        flag = true;
      }
    });

    if (flag) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/user-action/login?redirect=${to.fullPath}`);
    }
  }
});
