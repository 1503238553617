// import Viewer from 'v-viewer';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission"; // permission control
import "./main.less";
import * as vClickOutside from "v-click-outside-x";
import './config/rem';
// import * as VueGoogleMaps from 'vue2-google-maps'

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyBDaeWicvigtP9xPv919E-RNoxfvC-Hqik',
//     libraries: 'places',
//   },
// })

import Skeleton from './skeleton.js'
Vue.component(Skeleton.name, Skeleton)

// import 'viewerjs/dist/viewer.css';
// Vue.use(Viewer, {
//   defaultOptions: {
//     zIndex: 9999,
//   },
// });

// Vue.use(vClickOutside);

// import * as echarts from "echarts";
// Vue.prototype.$echarts = echarts;

import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

import scroll from "vue-seamless-scroll";
Vue.use(scroll);

// =========
// import "video.js/dist/video-js.css";
// =========

// =========
import ViewUI from "view-design";
import "./iview.less";
import "./var.less";
import locale from "view-design/dist/locale/en-US";
Vue.use(ViewUI, { locale });
// =========

// =========
// import VueQuillEditor from "vue-quill-editor";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
const toolbarOptions = [
  //["bold", "italic", "underline", "strike"], // toggled buttons
  //["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  //[{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  //[{ direction: "rtl" }], // text direction

  //[{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  //[{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  // [{ align: [] }],

  ["clean"] // remove formatting button
];
// Vue.use(VueQuillEditor, {
//   modules: {
//     toolbar: toolbarOptions
//   }
// });
// =========

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

app.$Message.config({
  duration: 4,
  top: 250
});

router.afterEach(to => {
  window.gtag("config", "G-QM091MB2E6", {
    page_path: to.fullPath
  });
});
// require('animate.css/animate.min.css');
// import {WOW} from 'wowjs'
// Vue.prototype.$wow = new WOW({
//   boxClass: 'wow', // default
//   animateClass: 'animated', // default
//   offset: 150, // default
//   mobile: true, // default
//   live: false,
//
//   callback: function(box) {
//     // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
//   }
// })

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)

// import LogRocket from 'logrocket';
// LogRocket.init('wewwk5/connexima');

Vue.prototype.$mobileWidth = 767;
Vue.prototype.$padWidth = 1024;

// import Vue2OrgTree from 'vue2-org-tree'
//
// Vue.use(Vue2OrgTree)

import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
