var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ivu-affix"},[_c('Header',{staticClass:"header sc-width"},[(!_vm.role)?[_c('div',{staticClass:"search-box"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/index"}},[_c('img',{attrs:{"src":require("@/assets/logo-w.png"),"alt":""}})])],1),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"navs "},[_c('div',{staticClass:"content"},[_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/BuyerSpace'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/buyer-space')}}},[_vm._v(" BuyerSpace ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/maxdata'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/maxdata')}}},[_vm._v(" MaxData ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/maxtrade'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/maxtrade')}}},[_vm._v(" MaxTrade ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/max-sales'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/max-sales')}}},[_vm._v(" MaxSales ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: _vm.$route.fullPath == '/maxtrade' }
                ],on:{"click":function($event){return _vm.redirect('contact-us')}}},[_vm._v(" Contact us ")])])])]),_c('div',{staticClass:"register-and-login"},[_c('div',{staticClass:"login  search-button",on:{"click":_vm.loginWithRedirect}},[_vm._v(" Sign In ")]),_c('a',{staticClass:"request-demo-link",attrs:{"href":"https://calendly.com/jingqiu-uk4/30min","target":"_blank"}},[_c('div',{staticClass:"request-demo"},[_vm._v(" Request Demo ")])]),_c('div',{staticClass:"register ",on:{"click":_vm.registerWithRedirect}},[_vm._v(" Free Sign Up ")])])]:[_c('MatchMedia',{attrs:{"query":"(max-width: 840px)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var matches = ref.matches;
return [(matches)?_c('div',{staticStyle:{"margin-left":"20px"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.drawerVisible = true}}},[_c('Icon',{attrs:{"type":"ios-menu"}})],1)],1):_c('fragment',[_c('div',{staticClass:"search-box"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/index"}},[_c('img',{attrs:{"src":require("@/assets/logo-w.png"),"alt":""}})])],1),_c('div',{staticClass:"menu menu-logged-in"},[_c('div',{staticClass:"navs "},[_c('div',{staticClass:"content"},[_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/BuyerSpace'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/buyer-space')}}},[_vm._v(" BuyerSpace ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/maxdata'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/maxdata')}}},[_vm._v(" MaxData ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/maxtrade'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/maxtrade')}}},[_vm._v(" MaxTrade ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: ['/max-sales'].includes(_vm.$route.path) }
                ],on:{"click":function($event){return _vm.jumpToMenu('/max-sales')}}},[_vm._v(" MaxSales ")]),_c('div',{class:[
                  'nav',
                  'font-black',
                  { active: _vm.$route.fullPath == '/maxtrade' }
                ],on:{"click":function($event){return _vm.redirect('contact-us')}}},[_vm._v(" Contact us ")]),_c('a',{staticClass:"request-demo-link",attrs:{"href":"https://calendly.com/jingqiu-uk4/30min","target":"_blank"}},[_c('div',{class:[
                  'nav',
                  'font-black' ]},[_vm._v(" Request Demo ")])])])])])])]}}])}),_c('div',{staticClass:"notif-user"},[_c('Dropdown',{staticClass:"dropmenu",attrs:{"trigger":"click","placement":"bottom-end"},on:{"on-click":_vm.clickDropdown}},[_c('div',{staticClass:"dropdown",on:{"click":_vm.showMenusMethod}},[(_vm.avatar && _vm.avatar.indexOf(_vm.defaultAvatarUrl) > -1)?_c('VueAvatar',{staticClass:"user-avatar-n",attrs:{"username":_vm.full_name,"backgroundColor":"#fff","color":"#FF4835"}}):_c('Avatar'),(!_vm.showMenus)?_c('Icon',{staticClass:"icon",staticStyle:{"font-size":"20px"},attrs:{"type":"md-arrow-dropdown","color":"#fff"}}):_c('Icon',{staticClass:"icon",staticStyle:{"font-size":"20px"},attrs:{"type":"md-arrow-dropup","color":"#fff"}})],1),(_vm.showMenus)?_c('div',{staticClass:"dropdown-menu"},[_c('div',{staticClass:"logo"},[(_vm.avatar && _vm.avatar.indexOf(_vm.defaultAvatarUrl) > -1)?_c('VueAvatar',{staticClass:"user-avatar-n-down",attrs:{"username":_vm.full_name,"backgroundColor":"#eee","color":"#FF4835"}}):_c('Avatar')],1),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.full_name))]),_c('div',{staticClass:"email"},[_vm._v(_vm._s(_vm.email))]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"item-name",on:{"click":function($event){return _vm.jumpTo('/user-profile')}}},[_vm._v(" My Profile ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"item-name",on:{"click":function($event){return _vm.jumpTo('/my-inquiries')}}},[_vm._v(" My Products ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"logout",on:{"click":_vm.logout}},[_vm._v(" Logout ")])]):_vm._e()])],1)]],2),_c('PopLogin',{ref:"popLogin",on:{"register":_vm.register}}),_c('PopRegister',{ref:"popRegister",on:{"successRegister":_vm.successRegister,"login":_vm.login}}),_c('PopRegisterEmail',{ref:"popRegisterEmail",on:{"successRegister":_vm.successRegister,"login":_vm.login}}),_c('PopRegisterSuccess',{ref:"popRegisterSuccess"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }